import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss']
})
export class CampaignsComponent implements OnInit {

  constructor(public router : Router) { }

  ngOnInit() {
  }

  navigate(page) {console.log(page);
  	this.router.navigate([page]);
  }

}
