import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from '../global.service';
import { Http,Headers, RequestOptions } from '@angular/http';
import { CommonModule } from '@angular/common';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';


declare var jquery:any;
declare var $ :any;

export interface PeriodicElement {
  ApplicationId: string;
  BankName: string;
  BankId: string;
  DistCodeName: string;
  TehsilCode: string;
  VillageCode: string;
  Amount: string;
  ApplicationDate: string;
  Status: string;
  Action: string;
}
@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss']
})
export class ApplicationComponent implements OnInit {

@ViewChild(MatPaginator) paginator: MatPaginator;
@ViewChild(MatSort) sort: MatSort;

displayedColumns: string[] = ['Bank Name', 'District Code', 'Tehsil Code',  'Village Code', 'Amount', 'Application Date',  'Action'];
dataSource = new MatTableDataSource();



	public UserList:any
	public errorMessage:any=''
  public successMessage:any=''
	public UserBankId:any=''

  constructor(public global : GlobalService,private route: ActivatedRoute,private router: Router) {
    this.UserBankId=this.global.userDetails['UserBankId'];
    this.global.checkUser();
  	this.getUserList(this.UserBankId);    
  }


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  

chnage_application_status(appid,status){
  if(confirm("Are you sure to "+status+ " this User")) {

	this.global.post(this.global.lrsAdminLocalUrl + "application_status", {"Id":appid,"Status":status}, data => {
     	if(data.Error==false){
     	this.successMessage=data.ErrorMessage;
     	}else{
         this.errorMessage=data.ErrorMessage
     	}
     	this.getUserList(this.UserBankId);
    }, err => {
      console.log(err);
     
    });
  }
}

// DeleteUser(userId){
// 	this.global.post(this.global.lrsAdminLocalUrl + "delete_user", {"userId":userId}, data => {
//      	if(data.Error==false){
//      	this.successMessage=data.ErrorMessage;
//      	}else{
//      	 this.errorMessage=data.ErrorMessage;
//      	}     	
//     }, err => {
//       console.log(err);
     
//     });
//     this.getUserList(this.UserBankId);
// }



getUserList(UserBankId){
    
    this.global.post(this.global.lrsAdminLocalUrl + "get_application_list", {'UserBankId':UserBankId}, data => {
     	// console.log(data.UserInfo);
     	if(data.UserInfo!=''){
     		this.UserList = data.UserInfo;
        this.dataSource = new MatTableDataSource(data.UserInfo);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

     	}else{
     		this.UserList = '';
     	}
     	
    
    }, err => {
      console.log(err);
     
    });
  }

 	navigate(page) {    
    this.global.navigate(page);    
	}


  ngOnInit() {
  }

}