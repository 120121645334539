import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from "@angular/http";
import "rxjs/add/operator/map";
import { log } from 'util';
// import { ToastrService } from 'ngx-toastr';


@Injectable()

export class GlobalService {

  public scannedDocs = '';
  public consumerApiUrl = '';
  public patwariGeoLocation = '';
  public lrsAdminServerUrl = '';
  public lrsAdminLocalUrl = '';
  public lrsAdminLocalUrl1 = '';
  public lrsAdminUrl = '';
  public GetNicBanks = '';
  public isFormDirty = false;
  public loader = false;
  public alertMessage = "";
  public userDetails = {};
  public lists_values: any;
  public lists: any;

  constructor(private http: Http, public router: Router, private route: ActivatedRoute) { 
    console.log({host: window.location.hostname});
    if(window.location.hostname == 'localhost'){
      this.lrsAdminUrl = 'http://localhost/admin-portal-backend/';
      this.lrsAdminLocalUrl = 'http://localhost/admin-portal-backend/';
      this.GetNicBanks = 'https://girdawari-api.rbaas.in/nic_apis/getGaon';
      this.lrsAdminServerUrl = 'https://girdawari-api.rbaas.in/lrs_admin/';
      this.patwariGeoLocation = 'https://girdawari-api.rbaas.in/patwari/geoLocations';
      this.consumerApiUrl = 'https://girdawari-api.rbaas.in/common_apis/nic_list';
      this.scannedDocs = 'https://girdawari-api.rbaas.in/reports/';
    }else if(window.location.hostname.match('staging') || window.location.hostname.match('RMS-DPAP-LIN511')){
      this.lrsAdminUrl = 'http://10.70.230.171/admin-portal-backend/';
      this.lrsAdminLocalUrl = 'http://10.70.230.171/admin-portal-backend/';
      this.GetNicBanks = 'http://10.70.230.171/patwari/nic_apis/getGaon';
      this.lrsAdminServerUrl = 'http://10.70.230.171/patwari/lrs_admin/';
      this.patwariGeoLocation = 'http://10.70.230.171/patwari/patwari/geoLocations';
      this.consumerApiUrl = 'http://10.70.230.171/patwari/common_apis/nic_list';
      this.scannedDocs = 'http://10.70.230.171/patwari/reports/';
    }else{
      this.lrsAdminUrl = 'https://admin-api.rbaas.in/';
      this.lrsAdminLocalUrl = 'https://admin-api.rbaas.in/';
      this.GetNicBanks = 'https://girdawari-api.rbaas.in/nic_apis/getGaon';
      this.lrsAdminServerUrl = 'https://girdawari-api.rbaas.in/lrs_admin/';
      this.patwariGeoLocation = 'https://girdawari-api.rbaas.in/patwari/geoLocations';
      this.consumerApiUrl = 'https://girdawari-api.rbaas.in/common_apis/nic_list';
      this.scannedDocs = 'https://girdawari-api.rbaas.in/reports/';
    }
  }

  public navigate(page, obj = {}) {
    if (Object.getOwnPropertyNames(obj).length == 0) {
      this.router.navigate([page]);
    } else {
      this.router.navigate([page, obj]);
    }
  }




  checkUser(): any {
    if (localStorage.getItem('userDetails')) {
      this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
      if (this.userDetails['UserType'] == 'bank') {
        this.router.navigate(['bankuser']);
      }
    } else {
      this.router.navigate(['login']);
    }
  }

  public post(url, body, successCallback, failedCallback, loader = true, text = "Please wait...") {
    let headers = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let options = new RequestOptions({
      headers: headers
    });
    if (loader) {
      this.loader = true;
    }
    this.http.post(url, body, options)
      .map(res => res.json())
      .subscribe(
        data => {
          this.loader = false;
          successCallback(data);
        },
        err => {
          this.loader = false;
          failedCallback(err);
        }
      );
  }

  public get(
    url,
    successCallback,
    failedCallback,
    loader = true,
    text = "प्रतीक्षा करें..."
  ) {
    this.http
      .get(url)
      .map(res => res.json())
      .subscribe(
        data => {
          successCallback(data);
        },
        err => {
          failedCallback(err);
        }
      );
  }

  filterTypeAhead(data = [], typeAheadValue, field = 'name'): any {
    return data.filter((eachData) => {
      if (eachData[field] && eachData[field].indexOf(typeAheadValue) != -1)
        return eachData;
    });
  }

  // get static reports by area
  public staticReportByArea() {
    return this.http.get("https://demo.synoriq.com/api_lrs/patwari/staticReports").map((res: Response) => res.json())
  }

  // get khatedar count
  public khatedarCount() {
    return this.http.get("https://demo.synoriq.com/api_lrs/patwari/khatedarNumberReport").map((res: Response) => res.json())
  }

  // get irigater method count
  public irigaterMethod() {
    return this.http.get("https://demo.synoriq.com/api_lrs/patwari/irigationMethodReport").map((res: Response) => res.json())
  }

  public getGeoData(geoType, searchKey = '', sucess, error) {
    let body = "type=" + geoType + "&searchKey=" + searchKey;
    this.post(this.patwariGeoLocation, body, (data) => {
      if (data.response != 0) {
        sucess(data.response);
      }
    }, (err) => {
      error(err);
    });
  }

  public getLocalStorage(key) {
    return JSON.parse(localStorage.getItem(key));
  }

  public setLocalStorage(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
  }

}
