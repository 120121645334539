import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from '../global.service';
import { Http,Headers, RequestOptions } from '@angular/http';
import { CommonModule } from '@angular/common';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';


declare var jquery:any;
declare var $ :any;


export interface PeriodicElement {
  Name: string;
  Email: string;
  Phone: string;
  SSOID: string;
  BankId: string;
  BankName: string;
  BranchId: string;
  BranchName: string;
  IFSC: string;
  MICR: string;
  BranchLoc: string;
  JoinDate: string;  
  Type: string;
  Status: string;
  Action: string;
}
@Component({
  selector: 'app-departmentusers',
  templateUrl: './departmentusers.component.html',
  styleUrls: ['./departmentusers.component.scss']
})
export class DepartmentusersComponent implements OnInit {

@ViewChild(MatPaginator) paginator: MatPaginator;
@ViewChild(MatSort) sort: MatSort;

displayedColumns: string[] = ['SSO Id', 'Phone', 'Bank Id', 'Branch Id', 'IFSC', 'MICR',  'JoinDate', 'Type', 'Status', 'Action'];
//displayedColumns: string[] = ['SSO Id', 'Email', 'Phone', 'Name', 'Bank Id','Bank Name', 'Branch Id', 'Branch Name', 'Location', 'IFSC', 'MICR',  'JoinDate', 'Type', 'Status', 'Action'];
dataSource = new MatTableDataSource();



	public UserList:any
	public errorMessage:any=''
  public successMessage:any=''
	public UserBankId:any=''

  constructor(public global : GlobalService,private route: ActivatedRoute,private router: Router) {
    this.UserBankId=this.global.userDetails['UserBankId'];
    this.global.checkUser();
  	this.getUserList(this.UserBankId);    
  }


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  

Block_Bank_User(userId,status,type){
	this.global.post(this.global.lrsAdminLocalUrl + "block_bank_user.php", {"userId":userId,"Status":status,"Type":type}, data => {
     	if(data.Error==false){
     	this.successMessage=data.ErrorMessage;
     	}else{
         this.errorMessage=data.ErrorMessage
     	}
     	this.getUserList(this.UserBankId);
    }, err => {
      console.log(err);
     
    });

}

// DeleteUser(userId){
// 	this.global.post(this.global.lrsAdminLocalUrl + "delete_user", {"userId":userId}, data => {
//      	if(data.Error==false){
//      	this.successMessage=data.ErrorMessage;
//      	}else{
//      	 this.errorMessage=data.ErrorMessage;
//      	}     	
//     }, err => {
//       console.log(err);
     
//     });
//     this.getUserList(this.UserBankId);
// }



getUserList(UserBankId){
    
    this.global.post(this.global.lrsAdminLocalUrl + "get_bankdepartuser_list", {'UserBankId':UserBankId}, data => {
     	console.log(data.UserInfo);
     	if(data.UserInfo!=''){
     		this.UserList = data.UserInfo;
        this.dataSource = new MatTableDataSource(data.UserInfo);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

     	}else{
     		this.UserList = '';
     	}
     	
    
    }, err => {
      console.log(err);
     
    });
  }

 	navigate(page) {    
    this.global.navigate(page);    
	}


  ngOnInit() {
  }

}