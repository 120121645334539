import { Component, OnInit } from '@angular/core';
import { GlobalService } from './../global.service';
import {Observable, Subject} from 'rxjs/Rx';

@Component({
  selector: 'app-khatedar-count',
  templateUrl: './khatedar-count.component.html',
  styleUrls: ['./khatedar-count.component.scss']
})
export class KhatedarCountComponent implements OnInit {

   areaData = [];   
  constructor(public global : GlobalService) {
     }

  ngOnInit() {
     this.getTehsilData();
  }
getTehsilData(){
    this.global.khatedarCount().subscribe((data)=>{
      if(data && data){
        this.areaData = data;
        console.log("test",this.areaData);
      }else{
        console.log("data not found");
      }
    });
  
}
}
