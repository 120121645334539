import { Component, OnInit,ViewChild } from '@angular/core';
import { GlobalService } from '../global.service';
import { Http,Headers, RequestOptions } from '@angular/http';
import { Validators, FormBuilder, FormGroup, FormControl,FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { UsersComponent} from '../users/users.component';

@Component({
  selector: 'app-banklogin',
  templateUrl: './banklogin.component.html',
  styleUrls: ['./banklogin.component.scss']
})
export class BankloginComponent implements OnInit {

 registerForm: FormGroup;
    loading = false;
    submitted = false;   
    public errorMessage:any ='';
    public UserType:any ='';

  constructor(public http : Http,public global: GlobalService,private formBuilder: FormBuilder,private router: Router,) {
    
   }

  ngOnInit() {

  	this.registerForm = this.formBuilder.group({
	Username:  ['', [Validators.required]],
	Password:  ['', [Validators.required]],
	});
  }

  get f() { return this.registerForm.controls; }


   onSubmit() { 
  	this.submitted = true;
  	if (this.registerForm.invalid) {
            return;
    }

    this.global.post(this.global.lrsAdminLocalUrl + "bank_login", this.registerForm.value, data => {
     	if(data.Error==false){
     	this.router.navigate(["users"]);
     	}else{
     	 this.errorMessage=data.ErrorMessage;
     	 // this.global.showError(data.ErrorMessage);     	 	
     	}
    }, err => {
      console.log(err);
     
    });
  }

}
