import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from '../global.service';
import { Http,Headers, RequestOptions } from '@angular/http';
import { CommonModule } from '@angular/common';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';


declare var jquery:any;
declare var $ :any;


export interface PeriodicElement {
  UserName: string;
  Date: string;
  FileAction: string;
  Remark: string;
}


@Component({
  selector: 'app-report-detail',
  templateUrl: './report-detail.component.html',
  styleUrls: ['./report-detail.component.scss']
})
export class ReportDetailComponent implements OnInit {


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['UserName', 'Date', 'FileAction',  'Remark'];
  dataSource = new MatTableDataSource();


public FilesInfo:any
	public errorMessage:any=''
	public successMessage:any=''
	public fileid:any=''

  constructor(public http : Http,public global: GlobalService,private activatedRoute: ActivatedRoute) {
  	this.fileid = activatedRoute.snapshot.paramMap.get('Id');
  	this.getReportsDetails(this.fileid);
  }


applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

getReportsDetails(fileid){    
    this.global.post(this.global.lrsAdminLocalUrl + "get_report_details", {"fileid":fileid}, data => {
    	console.log(data.FilesInfo);
     	if(data.FilesInfo!=''){
     		this.FilesInfo = data.FilesInfo;
        this.dataSource = new MatTableDataSource(data.FilesInfo);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
     	}else{
     		this.FilesInfo = '';
     	}    	
    
    }, err => {
      console.log(err);
     
    });
  }

 	navigate(page) {    
    this.global.navigate(page);    
	}




  ngOnInit() {
  }

}
