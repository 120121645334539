import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator, MatTableModule } from '@angular/material';
import {CdkTableModule, DataSource} from '@angular/cdk/table';
import * as XLSX from 'xlsx';
import { GlobalService } from './../global.service';
// import { DatePipe } from '@angular/common';
type AOA = any[][];

export interface allRecords {
  
  userName: string;
  // date: Date;
  docType: string;
  // tehsilsName: string;
  noOfVillages: number;
  // totalVillageDataScanned: number;
  scannedPages: number;
  taggedPage: number;
  characterCount: number;
  tehsil: string;
  ilr: string;
  patwar: string;
  village: string;
  barcode: Number;
  samwat: string;
  scannedPage: number;
  taggedPages: number;
}

@Component({
  selector: 'app-user-wise',
  templateUrl: './user-wise.component.html',
  styleUrls: ['./user-wise.component.scss']
})
export class UserWiseComponent implements OnInit {
  startDate= "";
  endDate="";
  displayedColumns: string[] = ['userName', 'docType', 'taggedPages', 'characterCount'];
  dataSource: MatTableDataSource<allRecords>;

  geoData = {
    district :{
      name:"",
      censusCode:""
    },
    tehsil:{
      name:"",
      censusCode:""  
    },
    village:{
      name:"",
      censusCode:""  
    }
  }
  dateFilter = false;

  userName = '';
  tehsilsName = '';
  districtName = '';
  tehsilName = '';
  ilr = '';
  patwarMandal = '';
  villageName = '';
  selectDate = '';
  districtTypeAheadFlag = false;
  tehsilTypeAheadFlag = false;
  villageTypeAheadFlag = false;
  districtArr = [];
  tehsilArr = [];
  villageArr = [];
  ilrList=[];
  // totalScannedPages=0;
  totalTaggedPages=0;
  totalCharacterCount=0;
  public allData: any = '';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: AOA = [['USER NAME','NO. OF TOTAL PAGES TAGGED']];
  fileName: string = 'user_wise.xlsx';

  constructor(public global : GlobalService){

    this.getDistrict();

  }

  ngOnInit() {
  	this.getList();

  }

  // getTotalCost() {
  //   return this.data.map(t => this.totalTaggedPages).reduce((acc, value) => acc + value, 0);
  // }

  private getList(extraBody="") {
    var body = '';
    if(extraBody == '') {
      body = '';
    } else {
      body = extraBody;
    }
    // let body = "0startDate=" + this.startDate + "endDate=" + this.endDate;
    let me = this;
    console.log(body);
    this.global.post(this.global.scannedDocs + 'user_wise', body, function (data) {
      if (data.response) {
        me.dataSource = data.data;
        const data2: allRecords[] = [];
        data.data.forEach(function (each) {
          data2.push(me.createNewData(each));
        });
        me.dataSource = new MatTableDataSource(data2);
        me.setPaginator();
        if(extraBody != '') {
          me.dateFilter = true;
        }
      }
      else {
        alert("NO data");
      }
    }, function (err) {
      alert("Sorry! Something went wrong!");
    });
  }
clearFilter() {
  this.dateFilter = false; 
  this.startDate = ''; 
  this.endDate = ''; 
  this.getList();
}
  searchQuery() {
    if(this.startDate == '' || this.endDate== '') {
      alert("Please select start and end date");
      this.getList();
    } else {
      this.getList("startDate=" + this.startDate + "&endDate=" + this.endDate);
    }

  }

  setPaginator() {
    let me = this;
    setTimeout(()=>{
      me.dataSource.paginator = me.paginator;
      me.dataSource.sort = me.sort;
    },2);
  }

  createNewData(element): allRecords {
    return {
      // date:element.date,
      userName:element.userName,
      docType:element.docType,
      noOfVillages:element.noOfVillages,
      // totalVillageDataScanned:element.totalVillageDataScanned,
    scannedPages:element.scannedPages,
    taggedPage:element.taggedPage,
    characterCount: element.characterCount,
    tehsil:element.tehsil,
    ilr:element.ilr,
    patwar:element.patwar,
    village:element.village,
    barcode:element.barcode,
    samwat:element.samwat,
    scannedPage:element.scannedPage,
    taggedPages:element.taggedPages
    };
  }

  textValidation(event: any,typeAheadValue, geoVariable) {
    if (this[typeAheadValue].length !=0 && this[typeAheadValue] == this.geoData[geoVariable].name) {
      event.preventDefault();
    } 
  }

  typeAhead(event, variableName, listName, attributeName = 'name') {
    this[listName] = this.global.filterTypeAhead(this['temp'+listName], variableName, attributeName);
 
  }

  falseFlag() {
    setTimeout(() => {
      this.districtTypeAheadFlag = false;
      this.tehsilTypeAheadFlag = false;
      this.villageTypeAheadFlag = false;
    }, 400);
  }

  clearInput(type, clearVariable){
    // clearVariable='';
    if(type=='district' && clearVariable==this.districtName){
      this.districtName='';
      this.tehsilName='';
      this.villageName='';
    }
    else if(type=='tehsil' && clearVariable==this.tehsilName){
      this.tehsilName='';
      this.villageName='';
    }
    else if (type=='village' && clearVariable==this.villageName) {
      this.villageName='';
    }
    }

    export(): void {
      /* generate worksheet */
      this.data=[];
      this.totalTaggedPages=0;
      this.totalCharacterCount=0;
      this.data.push(['Operator', 'Document Type', 'No. of Tagged Pages', 'No. of Characters', '', 'Date', this.startDate+' to '+this.endDate ])
      this.dataSource.filteredData.forEach((value) => {
        let arr = [];
          // arr.push(this.datePipe.transform(value.date));
          arr.push(value.userName);
          arr.push(value.docType);
          arr.push(value.taggedPages);
          arr.push(value.characterCount);
          // arr.push(value.totalVillageDataScanned);
          // arr.push(value.scannedPages);
          // arr.push(value.scannedPage);
          // this.totalScannedPages += parseInt(value.scannedPage.toString());
          // arr.push(value.taggedPages);
          this.totalTaggedPages += parseInt(value.taggedPages.toString());
          this.totalCharacterCount += parseInt(value.characterCount.toString());
          this.data.push(arr);
        });
        this.data.push(['TOTAL','', this.totalTaggedPages, this.totalCharacterCount]);
        // console.log(this.totalScannedPages);
        // console.log(this.totalTaggedPages);
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        /* save to file */
        XLSX.writeFile(wb, this.fileName);
      }

    selectOption(listType, typeAheadValue, item, variableName, listvalue){
      this.global.isFormDirty = true;
     this[typeAheadValue] = item.name;
     this.geoData[variableName] = item;
     this.getNicList(listType, listvalue);
   }

   getNicList(listType, variableName ) {
    var body = "type="+listType+"&tehsilCode="+this.geoData.tehsil.censusCode+"&districtCode="+this.geoData.district.censusCode+"&villageCode="+this.geoData.village.censusCode+"&khata=0&khasra=0";
    this.global.post(this.global.consumerApiUrl, body, (data)=> {
      // this.global.loader=true;
      this[variableName] = data.data;
      this['temp'+variableName] = data.data;
      // console.log(this.geoData.village.name);
  }, function(err) {
});
}

getDistrict() {
  this.getNicList('DistrictMaster', 'districtArr');
}

}
