import { Component, OnInit } from '@angular/core';
import { GlobalService } from './../global.service';
import {Observable, Subject} from 'rxjs/Rx';

@Component({
  selector: 'app-irrigation-method',
  templateUrl: './irrigation-method.component.html',
  styleUrls: ['./irrigation-method.component.scss']
})
export class IrrigationMethodComponent implements OnInit {

 areaData = [];   
  constructor(public global : GlobalService) { }

  ngOnInit() {
    this.getTehsilData();
  }

getTehsilData(){
    this.global.irigaterMethod().subscribe((data)=>{
      if(data && data){
        this.areaData = data;
        console.log("test",this.areaData);
      }else{
        console.log("data not found");
      }
    });
}
}
