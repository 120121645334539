import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from '../global.service';
import { Http,Headers, RequestOptions } from '@angular/http';
import { CommonModule } from '@angular/common';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';

declare var jquery:any;
declare var $ :any;


export interface PeriodicElement {
  UserName: string;
  FileName: string;
  FileType: string;
  DistrictCode: string;
  FileLoc: string;
  FileSize: string;
  Date: string;
  Action: string;
}


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['UserName', 'FileName', 'FileType',  'DistrictCode', 'FileLoc', 'FileSize', 'Date', 'Action'];
  dataSource = new MatTableDataSource();
	
	public FilesInfo:any
	public errorMessage:any=''
	public successMessage:any=''

  constructor(public global : GlobalService,private route: ActivatedRoute,private router: Router) {   
    this.global.checkUser();  
  	this.getReportsList();
  }

applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }


getReportsList(){    
    this.global.post(this.global.lrsAdminLocalUrl + "get_report_list.php", '', data => {
    	console.log(data.FilesInfo);
     	if(data.FilesInfo!=''){
     		this.FilesInfo = data.FilesInfo;
        this.dataSource = new MatTableDataSource(data.FilesInfo);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
     	}else{
     		this.FilesInfo = '';
     	}    	
    
    }, err => {
      console.log(err);
     
    });
  }

 	navigate(page) {    
    this.global.navigate(page);    
	}




  ngOnInit() {
  	$(document).ready(function() {
    	$('#UserListTabel').DataTable();
	});

  }

}
