import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from './global.service';
import { log } from 'util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'app';
  newCampaignClass = 'active';
  settingsClass = '';
  createClass = '';
  notiClass = '';
  pageArr = [];
	userDetails = {};

  constructor(public global : GlobalService,private route: ActivatedRoute,private router: Router) {
    this.global.checkUser();
    this.pageArr['create'] = 'active';
  }


  ngOnInit() {

  }

  
  navigate(page) {
    this.router.navigate(['login']);
    for (let key in this.pageArr) {
      this.pageArr[key] = '';
    }
    this.pageArr[page] = 'active';
    this.global.navigate(page);
	}

	logout() {
		localStorage.clear();
		this.global.userDetails = {};
		this.router.navigate(['login']);
  }
}
