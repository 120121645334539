import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from '../global.service';
import { Http,Headers, RequestOptions } from '@angular/http';
import { CommonModule } from '@angular/common';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';

declare var jquery:any;
declare var $ :any;


export interface PeriodicElement {
  Name: string;
  Phone: string;
  Email: string;
  Type: string;
  Vendor: string;
  Status: string;
  Action: string;
}


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})


export class UsersComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['Name', 'Email', 'Phone',  'Type', 'Status', 'Action'];
  dataSource = new MatTableDataSource();
 

	public UserList:any
	public errorMessage:any=''
	public successMessage:any=''

  constructor(public global : GlobalService,private route: ActivatedRoute,private router: Router) {
    this.global.checkUser();
  	this.getUserList();    
  }


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  

BlockUser(userId,status){
	this.global.post(this.global.lrsAdminLocalUrl + "block_user", {"userId":userId,"Status":status}, data => {
     	if(data.Error==false){
     	this.successMessage=data.ErrorMessage;
     	}else{
     	 this.errorMessage=data.ErrorMessage;
     	}
     	this.getUserList();
    }, err => {
      console.log(err);
     
    });

}

DeleteUser(userId){
	this.global.post(this.global.lrsAdminLocalUrl + "delete_user", {"userId":userId}, data => {
     	if(data.Error==false){
     	this.successMessage=data.ErrorMessage;
     	}else{
     	 this.errorMessage=data.ErrorMessage;
     	}     	
    }, err => {
      console.log(err);
     
    });
    this.getUserList();
}



getUserList(){
    
    this.global.post(this.global.lrsAdminLocalUrl + "get_user_list.php", '', data => {
     	console.log(data.UserInfo);
     	if(data.UserInfo!=''){
     		this.UserList = data.UserInfo;
        this.dataSource = new MatTableDataSource(data.UserInfo);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

     	}else{
     		this.UserList = '';
     	}
     	
    
    }, err => {
      console.log(err);
     
    });
  }

 	navigate(page) {    
    this.global.navigate(page);    
	}


  ngOnInit() {  
  }

}
