import { Component, OnInit,ViewChild } from '@angular/core';
import { GlobalService } from '../global.service';
import { Http,Headers, RequestOptions } from '@angular/http';
import { Validators, FormBuilder, FormGroup, FormControl,FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersComponent} from '../users/users.component';


@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

	registerForm: FormGroup;
    loading = false;
    submitted = false;

    public errorMessage:any ='';
    public UserId:any
    public UserInfo:any;

   constructor(public http : Http,public global: GlobalService,private formBuilder: FormBuilder,private router: Router,private activatedRoute: ActivatedRoute) { 
   	this.UserId = activatedRoute.snapshot.paramMap.get('Id');
   	this.getUserInfo(this.UserId);
	}


	getUserInfo(userId){

		this.global.post(this.global.lrsAdminLocalUrl + "get_user_info", {"userId":userId}, data => {
     	if(data.Error==false){
     		this.UserInfo=data.UserInfo;

     		this.registerForm = this.formBuilder.group({
				userid:  [this.UserInfo.UserId, [Validators.required]],
				Username:  [this.UserInfo.UserName, [Validators.required]],
				Type:  [this.UserInfo.UserType, [Validators.required]],
				Phone:  [this.UserInfo.UserPhone, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
				Password:  [this.UserInfo.UserPass, [Validators.required, Validators.minLength(6)]],
				EMail:  [this.UserInfo.UserEmail, [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]]
			});


     	}else{
     	 this.errorMessage=data.ErrorMessage;
     	}
	    }, err => {
	      console.log(err);
	     
	    });

	}


  ngOnInit() {
  	
  }

  get f() { return this.registerForm.controls; }


  onSubmit() { 	

  	this.submitted = true;
  	if (this.registerForm.invalid) {
            return;
    }

    console.log("Submit Form");

    this.global.post(this.global.lrsAdminLocalUrl + "update_user", this.registerForm.value, data => {
     	if(data.Error==false){
     	this.router.navigate(["users"]);
     	}else{
     	 this.errorMessage=data.ErrorMessage;
     	}
    }, err => {
      console.log(err);
     
    });



  }

}
