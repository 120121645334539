import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from './../app.component';
import { HostListener } from '@angular/core';
import { GlobalService } from '../global.service';
// import { LocalStorageService } from 'angular-local-storage';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


	public username;
	public password;

	constructor(public global : GlobalService,private route: ActivatedRoute,private router: Router) {
		this.global.checkUser();
	}

	@HostListener('window:keydown', ['$event'])
	keyEvent(event: KeyboardEvent) {
		if (event.keyCode == 13) {
			if(this.username && this.password)
				this.login();
		}
	}
	ngOnInit() { }
  	
  	login() {
		this.global.post(this.global.lrsAdminUrl + "Userlogin.php", {"userName":this.username,"password":this.password}, data => {
	     	if(data.Error==false) {
	     		var user = {"UserId":data.User.UserId,"username":data.User.UserName,"UserEmail":data.User.UserEmail,"UserType":data.User.UserType,"UserSSOId":data.User.UserSSOId,"UserBankId":data.User.UserBankId};
				this.global.userDetails = user;
				if(data.User.UserType == 'admin') {
					this.global.navigate('create');
				} else if(data.User.UserType == 'survey') {
					this.global.navigate('users');
				} else if(data.User.UserType == 'bank') {
					this.global.navigate('bankuser');
				} else if(data.User.UserType == 'BankAdministrator') {
					this.global.navigate('bank_users');
				} else if(data.User.UserType == 'Checker') {
					this.global.navigate('application');
				}
				localStorage.setItem('userDetails', JSON.stringify(user));
	     	} else {
	     	 alert("You entered wrong username/password");
	     	}
	    }, err => {
	      console.log(err);
	    });


  }
}

