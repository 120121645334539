import { Component, OnInit, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GlobalService } from '../global.service';

@Component({
  selector: 'app-list-component',
  templateUrl: './list-component.component.html',
  styleUrls: ['./list-component.component.scss']
})
export class ListComponentComponent implements OnInit {
  @Input() list = [];
  @Output() selected = [];
  
  constructor() { }

  ngOnInit() {
  }

}
