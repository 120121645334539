import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../notification.service';

@Component({
  selector: 'app-dhara-notification',
  templateUrl: './dhara-notification.component.html',
  styleUrls: ['./dhara-notification.component.scss']
})
export class DharaNotificationComponent implements OnInit {

  constructor(public notification: NotificationService) { }

  ngOnInit() {
  }

}
