import { Component, OnInit,ViewChild } from '@angular/core';
import { GlobalService } from '../global.service';
import { Http,Headers, RequestOptions } from '@angular/http';
import { Validators, FormBuilder, FormGroup, FormControl,FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { UsersComponent} from '../users/users.component';

declare var $ : any;
declare var jquery : any;

@Component({
  selector: 'app-banksignup',
  templateUrl: './banksignup.component.html',
  styleUrls: ['./banksignup.component.scss']
})
export class BanksignupComponent implements OnInit {

	registerForm: FormGroup;
    loading = false;
    submitted = false;   
    public errorMessage:any ='';
    public UserType:any ='';
    public AllBanks:any='';
    public AllBranch:any='';

  constructor(public http : Http,public global: GlobalService,private formBuilder: FormBuilder,private router: Router,) {
     this.getUserType();
     this.getBank();
   }

   getBank(){
     this.global.post(this.global.GetNicBanks, 'type=bankMaster', data => {  
         this.AllBanks=data.data;             
      }, err => {
        console.log(err);       
      });
   }

   GetBankBranch(bankid){
     this.global.post(this.global.GetNicBanks, "type=branchMaster&BankID="+bankid, data => {  
       console.log("Response " + data.response);
       if(data.response==true){
         this.AllBranch=data.data;       
       }else{
         // this.global.showError("No Data Available");
         this.AllBranch=data.data;
       }
      }, err => {
        console.log(err);       
      });
   }

   getUserType(){
    this.global.post(this.global.lrsAdminLocalUrl + "get_Bankuser_type", '', data => {  
         this.UserType=data;       
      }, err => {
        console.log(err);       
      });
  }

ngOnInit() {


  	this.registerForm = this.formBuilder.group({
        bank_name:  ['', [Validators.required]],
        branch_name:  ['', [Validators.required]],
        name:  ['', [Validators.required, Validators.pattern('[A-Za-z]{3,}')]],
        Username:  ['', [Validators.required]],
        employee_id:  ['', [Validators.required]],
        joining_date:  ['', [Validators.required]],
        address:  ['', [Validators.required]],
      	Type:  ['', [Validators.required]],
      	Phone:  ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]{10}')]],
      	EMail:  ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]]
	});
  }

  get f() { return this.registerForm.controls; }


   onSubmit() { 
  	this.submitted = true;
  	if (this.registerForm.invalid) {
            return;
    }

    this.global.post(this.global.lrsAdminLocalUrl + "bank_signup", this.registerForm.value, data => {
     	if(data.Error==false){
     	this.router.navigate(["users"]);
     	}else{
     	 this.errorMessage=data.ErrorMessage;
        // this.global.showError(data.ErrorMessage);
     	}
    }, err => {
      console.log(err);
     
    });



  }



}
