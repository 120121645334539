import { Component, OnInit,ViewChild } from '@angular/core';
import { GlobalService } from '../global.service';
import { Http,Headers, RequestOptions } from '@angular/http';
import {SelectionModel} from '@angular/cdk/collections';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import "rxjs/add/operator/map";
import { log } from 'util';
export interface Element {
  name: string;
  district: number;
  tehsil: number;
  action: string;
}
@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})


export class CreateUserComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  districtList = [];
  tehsilList = [];
  ilrList = [];
  patwarList = [];
  villageList = [];
  patwarFlag = false;
  loaderFlag = false;
  //selection = new SelectionModel();
  displayedColumns = ['name', 'district', 'tehsil','action'];
  dataSource = new MatTableDataSource();
  filter = {
    geoType:'',
    id:'',
    name:'',
    geoName:''
  };
  dataForTable;
  geoObj = {
    district:'',
    tehsil:'',
    ilr:'',
    patwar:'',
    village:''
  };
  dataTable =  [];
 // @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(public http : Http,public global: GlobalService) {
    this.getUserList('patwari',(data)=>{
    //  console.log(data);
      if(data.response){
        console.log(data.data);
        this.dataForTable = data.data;
        this.setDataTable(this.dataForTable);
      }else{
        console.log("err:",data);

      }

    });
   // this.dataSource = new MatTableDataSource(this.dataTable);
    global.getGeoData('get_districts','',(data)=>{
      this.districtList = data;
      console.log(this.districtList);

    },(err)=>{
      console.log(err);
    });
  }

  ngOnInit() {

    // this.dataSource = new MatTableDataSource(this.dataTable);
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }
  changeTab(tabs){
    //console.log(tabs);
    this.getUserList(tabs,(data)=>{
      //  console.log(data);
        if(data.response){
          console.log(data.data);
          this.dataForTable = data.data;
          this.setDataTable(this.dataForTable);
        }else{
          console.log("err:",data);

        }

      });
  }
  getGeo(type,i) {
    console.log(i);
    let id;
    let name;
    if(type == 'get_tehsil'){
      id = this.districtList[i].id;
      name = this.districtList[i].name;
    }if(type == 'get_ilr'){
      id = this.tehsilList[i].id;
      name = this.tehsilList[i].name;
    }if(type == 'get_patwar'){
      id = i;
    }if(type == 'get_online_village'){
      id = i;
    }
    if(type == 'village'){
      this.filter.geoType = 'village';
      this.filter.id = i;
    }
    else{
          this.global.getGeoData(type,id,(data)=>{
          if(type == 'get_tehsil'){
            this.tehsilList = data;
            this.filter.geoType = 'district';
            this.filter.id = id;
            this.filter.geoName = name;
            this.ilrList = [];
          } if(type == 'get_ilr'){
            this.ilrList = data;
            this.filter.geoType = 'tehsil';
            this.filter.id = id;
            this.filter.geoName = name;
            this.patwarList = [];
          } if(type == 'get_patwar'){
            this.patwarList = data;
            this.filter.geoType = 'ilr';
            this.filter.id = id;
            this.filter.geoName = '';
            this.villageList = [];
          } if(type == 'get_online_village'){
            this.villageList = data;
            this.filter.geoType = 'patwar';
            this.filter.id = id;
            this.filter.geoName = '';
          }
        },(err)=>{
          console.log(err);
        });
    }
    //console.log(this.filter);
  }

  getTehsil(){
    console.log(this.geoObj.district);

  }

  // applyFilter(filterValue: string) {
  //   filterValue = filterValue.trim(); // Remove whitespace
  //   filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
  //   this.dataSource.filter = filterValue;
  // }

  getUserList(user,cb){
    var body = "user=" +user;
    this.global.post(this.global.lrsAdminServerUrl + "get_user_list", body, data => {
     // console.log(data);
      cb(data);
    }, err => {
      console.log(err);
      cb(err);
    });
  }

  actionPerform(id){
    console.log(id);
  }

  setDataTable(data){
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loaderFlag = true;
  }
  dataTableFilter(filter){
    let filterValue = filter;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  search(){
    let body = "filter="+JSON.stringify(this.filter);
    console.log(this.filter);
    if(this.filter.geoType || (this.filter.geoType && this.filter.geoName)){
        if(this.filter.geoType == 'tehsil' || this.filter.geoType == 'district'){
          this.dataTableFilter(this.filter.geoName);
          if(this.filter.name){
            this.dataTableFilter(this.filter.name);
          }
        }
        else{
          this.global.post(this.global.lrsAdminServerUrl + "filter", body, data => {
              console.log(data);
              if(data.response == 1){
                this.dataForTable = data.data;
                this.setDataTable(this.dataForTable);
                if(this.filter.name){
                  this.dataTableFilter(this.filter.name);
                }
                // let filterValue = 'राकेश जाट';

                // filterValue = filterValue.trim(); // Remove whitespace
                // filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
                // this.dataSource.filter = filterValue;
              }
          }, err => {
            console.log(err);
          });
        }
      }else{
        this.dataTableFilter(this.filter.name);
      }
  }
}
