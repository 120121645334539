import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator, MatTableModule } from '@angular/material';
import {CdkTableModule, DataSource} from '@angular/cdk/table';
import * as XLSX from 'xlsx';
import { GlobalService } from './../global.service';

type AOA = any[][];

export interface allRecords {
  tehsilsName: string;
  noOfVillages: number;
  totalVillageDataScanned: number;
  scannedPages: number;
  taggedPage: number;
  tehsil: string;
  ilr: string;
  patwar: string;
  village: string;
  barcode: Number;
  samwat: string;
  scannedPage: number;
  taggedPages: number
}

@Component({
  selector: 'app-summarized-report',
  templateUrl: './summarized-report.component.html',
  styleUrls: ['./summarized-report.component.scss']
})
export class SummarizedReportComponent implements OnInit {

  displayedColumns: string[] = ['tehsilsName', 'totalVillageDataScanned', 'scannedPages', 'taggedPage'];
  dataSource: MatTableDataSource<allRecords>;

  geoData = {
    district :{
      name:"",
      censusCode:""
    },
    tehsil:{
      name:"",
      censusCode:""  
    },
    village:{
      name:"",
      censusCode:""  
    }
  }

  tehsilsName = '';
  districtName = '';
  tehsilName = '';
  ilr = '';
  patwarMandal = '';
  villageName = '';
  selectDate = '';
  districtTypeAheadFlag = false;
  tehsilTypeAheadFlag = false;
  villageTypeAheadFlag = false;
  districtArr = [];
  tehsilArr = [];
  villageArr = [];
  ilrList=[];
  // totalScannedPages=0;
  // totalTaggedPages=0;
  public allData: any = '';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: AOA = [['TEHSIL','TOTAL VILLAGES DATA SCANNED', 'NO. OF TOTAL PAGES SCANNED','NO. OF TOTAL PAGES TAGGED']];
  fileName: string = 'summarized_report.xlsx';

  constructor(public global : GlobalService){

    this.getDistrict();

  }

  ngOnInit() {
  	let body = "district="+this.districtName+"&tehsil="+this.tehsilName;
    let me = this;
    this.global.post(this.global.scannedDocs + 'summarized_report', body, function(data) {
      if(data.response) {
      me.dataSource = data.data;
      console.log(me.dataSource);
      const data2 : allRecords[] = [];
      data.data.forEach(function(each) {
          data2.push(me.createNewData(each));
      });
      me.dataSource = new MatTableDataSource(data2);
      console.log(me.dataSource);
      me.setPaginator();
    }else {
      alert("NO data");
    }
      
    }, function(err) {
        alert("Sorry! Something went wrong!");
        // console.log("error 2");
    });

  }

  setPaginator() {
    let me = this;
    setTimeout(()=>{
      me.dataSource.paginator = me.paginator;
      me.dataSource.sort = me.sort;
    },2);
  }

  createNewData(element): allRecords {
    return {
    tehsilsName:element.tehsilsName,
    noOfVillages:element.noOfVillages,
    totalVillageDataScanned:element.totalVillageDataScanned,
    scannedPages:element.scannedPages,
    taggedPage:element.taggedPage,
    tehsil:element.tehsil,
    ilr:element.ilr,
    patwar:element.patwar,
    village:element.village,
    barcode:element.barcode,
    samwat:element.samwat,
    scannedPage:element.scannedPage,
    taggedPages:element.taggedPages
    };
  }

  textValidation(event: any,typeAheadValue, geoVariable) {
    if (this[typeAheadValue].length !=0 && this[typeAheadValue] == this.geoData[geoVariable].name) {
      event.preventDefault();
    } 
  }

  typeAhead(event, variableName, listName, attributeName = 'name') {
    this[listName] = this.global.filterTypeAhead(this['temp'+listName], variableName, attributeName);
    console.log(this[listName]);
  }

  falseFlag() {
    setTimeout(() => {
      this.districtTypeAheadFlag = false;
      this.tehsilTypeAheadFlag = false;
      this.villageTypeAheadFlag = false;
    }, 400);
  }

  clearInput(type, clearVariable){
    // clearVariable='';
    if(type=='district' && clearVariable==this.districtName){
      this.districtName='';
      this.tehsilName='';
      this.villageName='';
    }
    else if(type=='tehsil' && clearVariable==this.tehsilName){
      this.tehsilName='';
      this.villageName='';
    }
    else if (type=='village' && clearVariable==this.villageName) {
      this.villageName='';
    }
    console.log(clearVariable);
    }

    export(): void {
      /* generate worksheet */
      this.dataSource.filteredData.forEach((value) => {
        let arr = [];
          arr.push(value.tehsilsName);
          arr.push(value.totalVillageDataScanned);
          arr.push(value.scannedPages);
          arr.push(value.taggedPage);
          // arr.push(value.scannedPage);
          // this.totalScannedPages += parseInt(value.scannedPage.toString());
          // arr.push(value.taggedPages);
          // this.totalTaggedPages += parseInt(value.taggedPages.toString());
          this.data.push(arr);
        });
        // this.data.push(['','' ,'', '','', '', '', '', 'TOTAL', this.totalScannedPages, this.totalTaggedPages]);
        // console.log(this.totalScannedPages);
        // console.log(this.totalTaggedPages);
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        /* save to file */
        XLSX.writeFile(wb, this.fileName);
      }

    selectOption(listType, typeAheadValue, item, variableName, listvalue){
      this.global.isFormDirty = true;
     this[typeAheadValue] = item.name;
     this.geoData[variableName] = item;
     this.getNicList(listType, listvalue);
   }

   getNicList(listType, variableName ) {
    var body = "type="+listType+"&tehsilCode="+this.geoData.tehsil.censusCode+"&districtCode="+this.geoData.district.censusCode+"&villageCode="+this.geoData.village.censusCode+"&khata=0&khasra=0";
    this.global.post(this.global.consumerApiUrl, body, (data)=> {
      // this.global.loader=true;
      this[variableName] = data.data;
      this['temp'+variableName] = data.data;
      console.log(variableName);
      console.log(this[variableName]);
      // console.log(this.geoData.village.name);
  }, function(err) {
});
}

getDistrict() {
  this.getNicList('DistrictMaster', 'districtArr');
}

// workingFilter(){
//   let body = "district=110&tehsil=00541";
//     this.global.post(this.global.scannedDocs + 'summarized_report', body, (data)=> {
//       console.log(data); 
//       if(data.response) {
//         this.dataSource = data.data;
//         const data2 : allRecords[] = [];
//         data.forEach(function(each) {
//             data2.push(this.createNewData(each));
//         });
//         this.dataSource = new MatTableDataSource(data2);
//         console.log(this.dataSource);
//         this.setPaginator();
//       } else {
//         alert("NO data");
//       }
      
//     }, function(err) {
//         alert("Sorry! Something went wrong!");
//         // console.log("error 2");
//     });
// }

}
