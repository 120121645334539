import { Component, OnInit } from '@angular/core';
import { GlobalService } from './../global.service';
import {Observable, Subject} from 'rxjs/Rx';
@Component({
  selector: 'app-static-report',
  templateUrl: './static-report.component.html',
  styleUrls: ['./static-report.component.scss']
})
export class StaticReportComponent implements OnInit {
   areaData = [];   
  constructor(public global : GlobalService) {
    
   }

  ngOnInit() {
    this.getTehsilData();
   // console.log('test report');
  }
   getTehsilData(){
    this.global.staticReportByArea().subscribe((data)=>{
      if(data && data){
        this.areaData = data;
        //console.log("test",this.areaData);
      }else{
        console.log("data not found");
      }
    });
  
}
}
