import { Component, OnInit } from '@angular/core';
import { Http, Headers, RequestOptions } from "@angular/http";
import "rxjs/add/operator/map";
import { GlobalService } from '../global.service';
import { log } from 'util';
import { Location } from '@angular/common';
@Component({
  selector: 'app-create-campaigns',
  templateUrl: './create-campaigns.component.html',
  styleUrls: ['./create-campaigns.component.scss']
})
export class CreateCampaignsComponent implements OnInit {
  constructor(public http : Http,public global: GlobalService, public location: Location) {

   }

  ngOnInit() {


  }


}
