import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../notification.service';
import { GlobalService } from '../global.service';
import { FormControl } from '@angular/forms';
import { log } from 'util';

@Component({
  selector: 'app-patwari-notification',
  templateUrl: './patwari-notification.component.html',
  styleUrls: ['./patwari-notification.component.scss']
})
export class PatwariNotificationComponent implements OnInit {

  allDistrict = [];
  alltehsil = [];
  allILR = [];
  allPatwar = [];
  geoId = [];
  districtList = [];
  tempTehsilList= [];
  tempILRList = [];
  tempPatwarList = [];
  tempPatwar = [];
  tehsilList = [];
  ilrList = [];
  patwarList = [];
  tehsilData = [];
  ilrData = [];
  patwar = [];
  patwarData = [];
  girdawariPercent =  [{key:'100', value:'100%'}, {key:'MT90', value:'More Than 90%'}, {key:'LT75', value:'Less Than 75%'}, {key:'LT50', value:'Less Than 50%'}, {key:'LT25', value:'Less Than 25%'}];
  districtFlag = new FormControl();
  tehsilFlag = new FormControl();
  ilrFlag = new FormControl();
  patwarFlag = new FormControl();
  div1 = true;
  div2 = true;
  div3 = true;

  selectedType = {
    geoType: "",
    geoId: [],
    userCategoryType: [],
    girdawariCount: [],
    topUserType: "",
    message: "",
    image: "",
    userType:""
  };

  constructor(public global: GlobalService, public notification: NotificationService) {
    global.getGeoData('get_districts','',(data)=>{
      this.districtList = data;
    },(err)=>{
      console.log(err);
    });
  }

  ngOnInit() {
  }

  setTehsilName(i,geoId){
    let id = [];
    if(!this.tempTehsilList[i]){
      this.tempTehsilList[i] = geoId;
    } else {
      this.tempTehsilList[i] = null;
    }
    this.tempTehsilList.forEach(element => {
      if(element != null){
        id.push(element);
      }
    });
    this.tehsilList = id;
    if(this.tehsilList.length == 1) {
      this.getTehsil(this.tehsilList);
    }
    this.selectedType.geoType = 'district';
    this.selectedType.geoId = this.tehsilList;
  }

  setILRName(i,geoId){
    let id = [];
    if(!this.tempILRList[i]){
      this.tempILRList[i] = geoId;
    } else {
      this.tempILRList[i] = null;
    }
    this.tempILRList.forEach(element => {
      if(element != null){
        id.push(element);
      }
    });
    this.ilrList = id;
    if(this.ilrList.length == 1) {
      this.getILR(this.ilrList);
    }
    this.selectedType.geoType = 'tehsil';
    this.selectedType.geoId = this.ilrList;
  }

  setPatwarName(i,geoId){
    let id = [];
    if(!this.tempPatwarList[i]){
      this.tempPatwarList[i] = geoId;
    } else {
      this.tempPatwarList[i] = null;
    }
    this.tempPatwarList.forEach(element => {
      if(element != null){
        id.push(element);
      }
    });
    this.patwarList = id;
    if(this.patwarList.length == 1) {
      this.getPatwar(this.patwarList);
    }
    this.selectedType.geoType = 'ilr';
    this.selectedType.geoId = this.patwarList;
  }

  setPatwarData(i,geoId){
    let id = [];
    if(!this.tempPatwar[i]){
      this.tempPatwar[i] = geoId;
    } else {
      this.tempPatwar[i] = null;
    }
    this.tempPatwar.forEach(element => {
      if(element != null){
        id.push(element);
      }
    });
    this.selectedType.geoType = 'patwar';
    this.selectedType.geoId = id;
  }

  getTehsil(idsArray) {
    this.global.getGeoData('get_tehsil',idsArray[0],(data)=>{
      this.tehsilData = data;
    },(err)=>{
      console.log(err);
    });
  }

  getILR(idsArray) {
    this.global.getGeoData('get_ilr',idsArray[0],(data)=> {
      this.ilrData = data;
    }, (err)=> {
      console.log(err);
    });
  }

  getPatwar(idsArray) {
    this.global.getGeoData('get_patwar', idsArray[0], (data) => {
      this.patwarData = data;
    }, (err) => {
      console.log(err);
    });
  }

  selectAllDistrict() {
    if(this.districtFlag.value.length != this.districtList.length){
      this.districtFlag.patchValue([0].concat(this.districtList));
    } else {
      this.districtFlag.patchValue([]);
    }
  }

  selectAllTehsil() {
    if(this.tehsilFlag.value.length != this.tehsilData.length){
      this.tehsilFlag.patchValue([0].concat(this.tehsilData.filter((element) => {
        if(element.online != 0) {
          return element;
        }
      })));
    } else {
      this.tehsilFlag.patchValue([]);
    }
  }

  selectAllILR() {
    if(this.ilrFlag.value.length != this.ilrData.length){
      this.ilrFlag.patchValue([0].concat(this.ilrData));
    } else {
      this.ilrFlag.patchValue([]);
    }
  }

  selectAllPatwar() {
    if(this.patwarFlag.value.length != this.patwarData.length){
      this.patwarFlag.patchValue([0].concat(this.patwarData));
    } else {
      this.patwarFlag.patchValue([]);
    }
  }

  sendNotification() {
    console.log(this.notification.userType);
     this.selectedType.userType = this.notification.userType;
    //console.log(this.selectedType);
    var body = "campainData=" +JSON.stringify(this.selectedType);
    this.global.post(this.global.lrsAdminServerUrl + "send_notification", body, data => {

    }, err => {});
  }
}
