import { Injectable } from '@angular/core';

@Injectable()
export class NotificationService {
  userType = 'all';
	showUsers = false;
	message = '';
  campainData = {};
  submitted = false;
  constructor() { }
  
  changeOnFile(event) {
    var file = event.target.files[0];
    this.campainData['fileName']=file['name'];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var data = "";
      data = reader.result['split'](",")[1];
      data.replace(" ", "");
      this.campainData['fileData'] = data;
    };
    console.log(file);
  }

  sendNotification() {
    console.log(this.campainData);
    this.submitted = true;
  }
}
