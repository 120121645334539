import { Component, OnInit,ViewChild } from '@angular/core';
import { GlobalService } from '../global.service';
import { Http,Headers, RequestOptions } from '@angular/http';
import { Validators, FormBuilder, FormGroup, FormControl,FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { UsersComponent} from '../users/users.component';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {


	  registerForm: FormGroup;
    loading = false;
    submitted = false;

    public errorMessage:any ='';
    public UserType:any ='';

   constructor(public http : Http,public global: GlobalService,private formBuilder: FormBuilder,private router: Router,) {
     // this.getUserType();
   }


// getUserType(){
//     this.global.post(this.global.lrsAdminLocalUrl + "get_user_info", '', data => {      
//          this.UserType=data.UsersType;       
//       }, err => {
//         console.log(err);       
//       });
//   }

  ngOnInit() {

  	this.registerForm = this.formBuilder.group({
	Username:  ['', [Validators.required]],
	Type:  ['', [Validators.required]],
	Phone:  ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
	Password:  ['', [Validators.required, Validators.minLength(6)]],
	EMail:  ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]]
	});
  }

  get f() { return this.registerForm.controls; }


  onSubmit() {

  	

  	this.submitted = true;
  	if (this.registerForm.invalid) {
            return;
    }

    console.log("Submit Form");

    this.global.post(this.global.lrsAdminLocalUrl + "add_new_user", this.registerForm.value, data => {
     	if(data.Error==false){
     	this.router.navigate(["users"]);
     	}else{
     	 this.errorMessage=data.ErrorMessage;
     	}
    }, err => {
      console.log(err);
     
    });



  }

}
