import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from '../global.service';
import { Http, Headers, RequestOptions } from '@angular/http';
import { CommonModule } from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material';


declare var jquery: any;
declare var $: any;


export interface PeriodicElement {
  Name: string;
  Email: string;
  Phone: string;
  SSOID: string;
  Createdate: string;
  BankId: string;
  BankName: string;
  BranchId: string;
  BranchName: string;
  IFSC: string;
  MICR: string;
  BranchLoc: string;
  JoinDate: string;
  Type: string;
  Status: string;
  Action: string;
}

@Component({
  selector: 'app-bankuser',
  templateUrl: './bankuser.component.html',
  styleUrls: ['./bankuser.component.scss']
})
export class BankuserComponent implements OnInit {

  displayedColumns: string[] = ['On Board Date', 'Name', 'SSO Id', 'Phone', 'Bank Name', 'Bank Id', 'Status', 'Action'];
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  public UserList: any
  public errorMessage: any = ''
  public successMessage: any = ''

  constructor(public global: GlobalService, private route: ActivatedRoute, private router: Router) {
    this.global.checkUser();
    this.getUserList();
  }


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }



  Block_Bank_User(userId, status, type) {
    if (status == 'inactive') {
      var msgStatus = 'deactivate';
    } else {
      var msgStatus = 'activate';
    }
    if (confirm("Are you sure to " + msgStatus + " this User")) {
      this.global.post(this.global.lrsAdminLocalUrl + "block_bank_user.php", { "userId": userId, "Status": status, "Type": type }, data => {
        if (data.Error == false) {
          this.successMessage = data.ErrorMessage;
        } else {
          this.errorMessage = data.ErrorMessage
        }
        this.getUserList();
      }, err => {
        console.log(err);

      });
    }

  }

  // DeleteUser(userId){
  // 	this.global.post(this.global.lrsAdminLocalUrl + "delete_user", {"userId":userId}, data => {
  //      	if(data.Error==false){
  //      	this.successMessage=data.ErrorMessage;
  //      	}else{
  //      	 this.errorMessage=data.ErrorMessage;
  //      	}     	
  //     }, err => {
  //       console.log(err);

  //     });
  //     this.getUserList();
  // }



  getUserList() {
    this.global.post(this.global.lrsAdminLocalUrl + "get_bankuser_list.php", { "type": "administrator" }, data => {
      if (data.UserInfo != '') {
        this.UserList = data.UserInfo;
        this.dataSource = new MatTableDataSource(data.UserInfo);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

      } else {
        this.UserList = '';
      }


    }, err => {
      console.log(err);

    });
  }

  navigate(page) {
    this.global.navigate(page);
  }


  ngOnInit() {
  }

}
