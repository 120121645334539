import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator, MatTableModule } from '@angular/material';
import {CdkTableModule, DataSource} from '@angular/cdk/table';
import * as XLSX from 'xlsx';
import { GlobalService } from './../global.service';

type AOA = any[][];

export interface allRecords {
  docType: string;
  date: string;
  district: string;
  tehsil: string;
  ilr: string;
  patwar: string;
  village: string;
  barcode: Number;
  samwat: string;
  scannedPage: number;
  taggedPages: number
}

@Component({
  selector: 'app-village-level-report',
  templateUrl: './village-level-report.component.html',
  styleUrls: ['./village-level-report.component.scss']
})
export class VillageLevelReportComponent implements OnInit {

  displayedColumns: string[] = ['village', 'date', 'docType', 'barcode', 'samwat', 'scannedPage','taggedPages'];
  dataSource: MatTableDataSource<allRecords>;

  geoData = {
    district :{
      name:"",
      districtId:""
    },
    tehsil:{
      name:"",
      tehsilId:""  
    },
    village:{
      name:"",
      villageId:""  
    }
  }

  docType = '';
  districtName = "";
  tehsilName = "";
  ilrName="";
  patwarName = '';
  villageName = '';
  searchKey = "";
  selectDate = '';
  districtTypeAheadFlag = false;
  tehsilTypeAheadFlag = false;
  ilrTypeAheadFlag = false;
  patwarTypeAheadFlag = false;
  villageTypeAheadFlag = false;
  districtArr = [];
  tehsilArr = [];
  villageArr = [];
  ilrArr=[];
  patwarArr=[];
  totalScannedPages=0;
  totalTaggedPages=0;
  public allData: any = '';
  public notShowToast:Boolean = false;
  district="";
  tehsil="";  
  ilr = "";
  patwar="";
  village="";


  tempVariable="";

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: AOA = [['VILLAGE', 'DATE(REGISTER CREATION)', 'DOCUMENT TYPE', 'BARCODE', 'SAMWAT', 'SCANNED PAGES', 'TAGGED PAGES']];
  fileName: string = 'village_level_report.xlsx';
  search_key={'i_geohierarchy_id_c':'','soil_class_c':'','m_crop_list_id_c':'','i_geohierarchy_id1_c':'','i_geohierarchy_id2_c':'','ilrId':'','patwarId':'','season_year_c':''};

  constructor(public global : GlobalService){

    this.getDistrict();

  }

  ngOnInit() {
    // this.showData();
  }

  showData(){
    let body = "searchKey="+this.searchKey;
    let me = this;
    this.global.post(this.global.scannedDocs + 'village_level', body, function(data) {
      if(data.response) {
      me.dataSource = data.data;
      console.log(me.dataSource);
      const data2 : allRecords[] = [];
      data.data.forEach(function(each) {
          data2.push(me.createNewData(each));
      });
      me.dataSource = new MatTableDataSource(data2);
      console.log(me.dataSource);
      me.setPaginator();
    }else {
      alert("NO data");
    }
      
    }, function(err) {
        alert("Sorry! Something went wrong!");
        // console.log("error 2");
    });
  }

  setPaginator() {
    let me = this;
    setTimeout(()=>{
      me.dataSource.paginator = me.paginator;
      me.dataSource.sort = me.sort;
    },2);
  }

  createNewData(element): allRecords {
    return {
    village:element.village,
    date:element.date,
    docType:element.docType,
    barcode:element.barcode,
    district:element.district,
    tehsil:element.tehsil,
    ilr:element.ilr,
    patwar:element.patwar,
    samwat:element.samwat,
    scannedPage:element.scannedPage,
    taggedPages:element.taggedPages
    };
  }

  textValidation(event: any,typeAheadValue, geoVariable) {
    if (this[typeAheadValue].length !=0 && this[typeAheadValue] == this.geoData[geoVariable].name) {
      event.preventDefault();
    } 
  }

  typeAhead(event, variableName, listName, attributeName = 'name') {
    this[listName] = this.global.filterTypeAhead(this['temp'+listName], variableName, attributeName);
    // console.log(this[listName]);
    // console.log(variableName);
  }

  falseFlag() {
    setTimeout(() => {
      this.districtTypeAheadFlag = false;
      this.tehsilTypeAheadFlag = false;
      this.ilrTypeAheadFlag = false;
      this.patwarTypeAheadFlag = false;
      this.villageTypeAheadFlag = false;
    }, 400);
  }

  clearInput(type, clearVariable){
    // clearVariable='';
    if(type=='district' && clearVariable==this.districtName){
      this.districtName='';
      this.tehsilName='';
      this.villageName='';
    }
    else if(type=='tehsil' && clearVariable==this.tehsilName){
      this.tehsilName='';
      this.villageName='';
    }
    else if (type=='village' && clearVariable==this.villageName) {
      this.villageName='';
    }
    console.log(clearVariable);
    }

    export(): void {
      /* generate worksheet */
      this.dataSource.filteredData.forEach((value) => {
        let arr = [];
          arr.push(value.village);
          arr.push(value.date);
          arr.push(value.docType);
          arr.push(value.barcode);
          arr.push(value.samwat);
          arr.push(value.scannedPage);
          this.totalScannedPages += parseInt(value.scannedPage.toString());
          arr.push(value.taggedPages);
          this.totalTaggedPages += parseInt(value.taggedPages.toString());
          this.data.push(arr);
        });
        this.data.push(['','' ,'', '', 'TOTAL', this.totalScannedPages, this.totalTaggedPages]);
        console.log(this.totalScannedPages);
        console.log(this.totalTaggedPages);
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        /* save to file */
        XLSX.writeFile(wb, this.fileName);
      }

    selectOption(listType, typeAheadValue, item, variableName, listvalue){
      this.global.isFormDirty = true;
        this[typeAheadValue] = item.name;
        this.geoData.district.districtId = item.id;
        this.searchKey=this.geoData.district.districtId;
        console.log(this.searchKey);
        // console.log(typeAheadValue);
        this.geoData[variableName] = item;
        this.getAreaList(listType, listvalue, typeAheadValue);
        // console.log(this.patwarArr);
        // console.log(this.villageArr);
        // console.log(this.tehsilArr);
        console.log(listvalue);
        // console.log(this.districtName);
   }

   getAreaList(listType, variableName, searchKey ) {
    var body = "type="+listType+"&searchKey="+this.searchKey;
    this.global.post(this.global.patwariGeoLocation, body, (data)=> {
      console.log(data.response);
      this[variableName] = data.response;
      console.log(this.searchKey);
      console.log(this[variableName]);
      this['temp'+variableName] = data.response;
      console.log(this[variableName]);
      this.showData();
      // console.log(this.geoData.village.name);
  }, function(err) {
});
}

getDistrict() {
  this.getAreaList('get_districts', 'districtArr', '');
}

// filters

}