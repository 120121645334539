import { RouterModule, Routes } from '@angular/router';
import { GlobalService } from './global.service';
import { CdkTableModule } from '@angular/cdk/table';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpModule } from '@angular/http';
import { AppComponent } from './app.component';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { CreateCampaignsComponent } from './create-campaigns/create-campaigns.component';
import { SettingsComponent } from './settings/settings.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { DeviceManagementComponent } from './device-management/device-management.component';
import { IntegrationConfigurationsComponent } from './integration-configurations/integration-configurations.component';
import { ScannedReportsComponent } from './scanned-reports/scanned-reports.component';
import { MatFormFieldModule,MatButtonToggleModule,MatListModule, MatInputModule, MatPaginatorModule, MatTableModule, MatSortModule, MatDatepickerModule, MatNativeDateModule, MatDividerModule,MatTooltipModule, MatSelectModule, MatCheckboxModule} from '@angular/material';
import { DataTablesModule } from 'angular-datatables';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SummarizedReportComponent } from './summarized-report/summarized-report.component';
import { HigherLevelSummaryComponent } from './higher-level-summary/higher-level-summary.component';
import { VillageLevelReportComponent } from './village-level-report/village-level-report.component';
import { InstallsOverviewComponent } from './installs-overview/installs-overview.component';
import { StaticReportComponent } from './static-report/static-report.component';
import { KhatedarCountComponent } from './khatedar-count/khatedar-count.component';
import { IrrigationMethodComponent } from './irrigation-method/irrigation-method.component';
import { UserWiseComponent } from './user-wise/user-wise.component';
import { LoginComponent } from './login/login.component';
import { DatePipe } from '../../node_modules/@angular/common';
import { HeaderComponent } from './header/header.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonModule} from '@angular/material/button';
import { FormAlertComponent } from './form-alert/form-alert.component';
import { DharaNotificationComponent } from './dhara-notification/dhara-notification.component';
import { PatwariNotificationComponent } from './patwari-notification/patwari-notification.component';
import { NotificationService } from './notification.service';
import { ListComponentComponent } from './list-component/list-component.component';
import { from } from 'rxjs/observable/from';
// import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { UsersComponent } from './users/users.component';
import { AddUserComponent } from './add-user/add-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { ReportComponent } from './report/report.component';
import { ReportDetailComponent } from './report-detail/report-detail.component';
import { Ng2TableModule } from 'ng2-table/ng2-table';
import { BankuserComponent } from './bankuser/bankuser.component';
import { HomeComponent } from './home/home.component';
import { BanksignupComponent } from './banksignup/banksignup.component';
import { BankloginComponent } from './banklogin/banklogin.component';

// import { ToastrModule } from 'ngx-toastr';
import { DepartmentusersComponent } from './departmentusers/departmentusers.component';
import { ApplicationComponent } from './application/application.component';
import { AllapplicationComponent } from './allapplication/allapplication.component';

@NgModule({
    declarations: [
      AppComponent,
      CampaignsComponent,
    CreateCampaignsComponent,
    SettingsComponent,
    NotificationsComponent,
    CreateUserComponent,
    DeviceManagementComponent,
    IntegrationConfigurationsComponent,
    ScannedReportsComponent,
    SummarizedReportComponent,
    HigherLevelSummaryComponent,
    VillageLevelReportComponent,
    InstallsOverviewComponent,
    StaticReportComponent,
    KhatedarCountComponent,
    IrrigationMethodComponent,
    UserWiseComponent,
    LoginComponent,
    HeaderComponent,
    FormAlertComponent,
    DharaNotificationComponent,
    PatwariNotificationComponent,
    ListComponentComponent,
    UsersComponent,
    AddUserComponent,
    EditUserComponent,
    ReportComponent,
    ReportDetailComponent,
    BankuserComponent,
    HomeComponent,
    BanksignupComponent,
    BankloginComponent,
    DepartmentusersComponent,
    ApplicationComponent,
    AllapplicationComponent,
    
  ],
  imports: [MatButtonToggleModule,MatDividerModule,MatExpansionModule,MatButtonModule,MatTooltipModule,MatListModule,
    BrowserModule,
    DataTablesModule,
    HttpClientModule,
    HttpModule,
    FormsModule, ReactiveFormsModule,
    RouterModule,
    AppRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    CdkTableModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatNativeDateModule,MatRadioModule,
    MatSelectModule, MatCheckboxModule,
    // InfiniteScrollModule,
    // ToastrModule.forRoot(),
  ],
  providers: [GlobalService,NotificationService,
  DatePipe],
  exports: [AppRoutingModule],
  bootstrap: [AppComponent]
})
export class AppModule { }