import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { InstallsOverviewComponent } from './installs-overview/installs-overview.component';
import { VillageLevelReportComponent } from './village-level-report/village-level-report.component';
import { HigherLevelSummaryComponent } from './higher-level-summary/higher-level-summary.component';
import { SummarizedReportComponent } from './summarized-report/summarized-report.component';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { CreateCampaignsComponent } from './create-campaigns/create-campaigns.component';
import { SettingsComponent } from './settings/settings.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { DeviceManagementComponent } from './device-management/device-management.component';
import { IntegrationConfigurationsComponent } from './integration-configurations/integration-configurations.component';
import { ScannedReportsComponent } from './scanned-reports/scanned-reports.component';
import { StaticReportComponent} from './static-report/static-report.component';
import { KhatedarCountComponent} from './khatedar-count/khatedar-count.component';
import { IrrigationMethodComponent} from './irrigation-method/irrigation-method.component';
import { UserWiseComponent } from './user-wise/user-wise.component';
import { LoginComponent } from './login/login.component';


///10-may-2019
import { UsersComponent } from './users/users.component';
import { AddUserComponent } from './add-user/add-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { ReportComponent } from './report/report.component';
import { ReportDetailComponent } from './report-detail/report-detail.component';
import { BankuserComponent } from './bankuser/bankuser.component';
import { BanksignupComponent } from './banksignup/banksignup.component';
import { BankloginComponent } from './banklogin/banklogin.component';
import { HomeComponent } from './home/home.component';
import { DepartmentusersComponent } from './departmentusers/departmentusers.component';
import { ApplicationComponent } from './application/application.component';
import { AllapplicationComponent } from './allapplication/allapplication.component';




const routes: Routes = [
    // {
    //     path : '',
    //     component : HomeComponent
    // },
    {
        path : 'new-campaign',
        component : CampaignsComponent
    },
    {
        path : 'settings',
        component : SettingsComponent
    },
    {
        path : 'create',
        component : CreateCampaignsComponent
    },
    {
        path : 'create-user',
        component : CreateUserComponent
    },
    {
        path : 'device-management',
        component : DeviceManagementComponent
    },
    {
        path : 'integration-configurations',
        component : IntegrationConfigurationsComponent
    },
    {
        path : 'notifications',
        component : NotificationsComponent
    },
    {
        path : 'scanned-report',
        component : ScannedReportsComponent
    },
    {
        path : 'summarized-report',
        component : SummarizedReportComponent
    },
    {
        path : 'higher-level-summary',
        component : HigherLevelSummaryComponent
    },
    {
        path : 'village-level-report',
        component : VillageLevelReportComponent
    },
    {
        path : 'installs-overview',
        component : InstallsOverviewComponent
    },
    {
        path : 'static-report-area',
        component : StaticReportComponent
    },
    {
        path : 'khatedar-number',
        component : KhatedarCountComponent
    },
    {
        path : 'irrigation-method',
        component : IrrigationMethodComponent
    },
    {
        path : 'user-wise',
        component : UserWiseComponent
    },

    ///10-may-2019
    {
        path : 'users',
        component : UsersComponent
    },
    {
        path : 'add-user',
        component : AddUserComponent
    },
    {
        path : 'edit-user/:Id',
        component : EditUserComponent
    },
    {
        path : 'report',
        component : ReportComponent
    },
    {
        path : 'report-detail/:Id',
        component : ReportDetailComponent
    },
    {
        path : 'bankuser',
        component : BankuserComponent
    },
    {
        path : 'bank_users',
        component : DepartmentusersComponent
    },
    {
        path : 'banksignup',
        component : BanksignupComponent
    },
    {
        path : 'banklogin',
        component : BankloginComponent
    },
    {
        path : 'application',
        component : ApplicationComponent
    },
    {
        path : 'all-application',
        component : AllapplicationComponent
    },
    {
        path : '**',
        component : LoginComponent
    }
    ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
